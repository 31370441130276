<template>
	<div class="product">
		<div class="product_title">
			<p class="product_title_cn">产品中心</p>
			<p class="product_title_en">PRODUCT CENTER</p>
		</div>

		<div class="product_con">
			<div class="product_list">

				<div v-for="(item, index) in list" :key="index" class="product_item" @click="itemClick(item, index)">
					<div class="product_item_con">
						<van-image class="product_item_con_img" fit="cover" lazy-load :src="item.product_img" />
						<div class="product_item_con_b">
							<span class="product_item_con_b_title">{{ item.product_name }}</span>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "index",
		data() {
			return {
				list: []
			}
		},
		created() {
			this.init();
		},
		methods: {
			async init() {
				this.$loading();
				const res = await this.$api.productList();
				this.$loading().clear();

				this.list = res.data;
			},

			itemClick(item, index) {
				this.$router.push('/product/' + item.product_id);
			}
		}
	}
</script>

<style lang="less" scoped>
	.product {
		width: 100%;
		max-width: 164rem;
		margin: 0 auto;
		padding: 10.5rem 0 9rem;
	}

	.product_title {
		color: @greenColor;
		margin-bottom: 4.5rem;
		padding-left: 1rem;
	}

	.product_title_cn {
		font-size: 5.4rem;
	}

	.product_title_en {
		font-size: 1.5rem;
		margin-top: 0.5rem;
	}

	.product_con {
		padding: 7rem 0;
		background-color: #eaf7e6;
		background-image: url("~@/assets/home_bg.jpg");
		background-repeat: no-repeat;
		background-size: 164rem 72rem;
		background-position: top center;
	}

	.product_list {
		width: 100%;
		max-width: 100rem;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		align-content: flex-start;
		padding: 0 1rem;
	}

	.product_item {
		width: 23.5%;
		height: 0;
		position: relative;
		padding-top: 23.5%;
		margin-right: 2%;
		margin-top: 3.5rem;
		cursor: pointer;
	}

	.product_item_con {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		background-color: #ffffff;
		position: absolute;
		top: 0;
		left: 0;
		overflow: hidden;
	}

	.product_item_con_img {
		flex-shrink: 0;
		width: 100%;
		height: 65%;
	}

	.product_item_con_b {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.product_item_con_b_title {
		font-size: 4rem;
		color: @greenDarkColor;
		font-family: 'shangshushui';
	}

	.product_item_con_b_desc {
		width: 100%;
		padding: 0 2rem;
		font-size: 1.8rem;
		text-align: center;
		color: @blackColor;
		.ellipsis;
	}




	@media screen and (min-width: 800px) {
		.product_item_con {
			padding: 1rem;
		}

		.product_item:nth-child(4n+4) {
			margin-right: 0;
		}

		.product_item:nth-child(1) {
			margin-top: 0;
		}

		.product_item:nth-child(2) {
			margin-top: 0;
		}

		.product_item:nth-child(3) {
			margin-top: 0;
		}

		.product_item:nth-child(4) {
			margin-top: 0;
		}
	}





	@media screen and (max-width: 800px) {
		.product {
			padding: 2.3rem 0 0;
		}

		.product_title {
			margin-bottom: 1.2rem;
			text-align: center;
		}

		.product_title_cn {
			font-size: 1.9rem;
		}

		.product_title_en {
			font-size: 0.6rem;
			margin-top: 0.2rem;
		}

		.product_con {
			padding: 2.5rem 0;
			background-size: 100% 25rem;
		}

		.product_item {
			width: 32%;
			padding-top: 32%;
			margin-top: 1rem;
		}

		.product_item:nth-child(3n+3) {
			margin-right: 0;
		}

		.product_item:nth-child(1) {
			margin-top: 0;
		}

		.product_item:nth-child(2) {
			margin-top: 0;
		}

		.product_item:nth-child(3) {
			margin-top: 0;
		}

		.product_item_con {
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
		}

		.product_item:nth-child(3n+1) .product_item_con {
			padding-left: 0.5rem;
		}

		.product_item:nth-child(3n+3) .product_item_con {
			padding-right: 0.5rem;
		}

		.product_item_con_img {
			height: 70%;
		}

		.product_item_con_b_title {
			font-size: 1.3rem;
		}

		.product_item_con_b_desc {
			font-size: 0.8rem;
			margin-top: 0.2rem;
		}
	}
</style>